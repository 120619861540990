import { QK_GET_PAYMENT_OPTIONS, QK_SHOW_3_DOTS_LOADING } from './queryKeys';

import { BPAPI } from './mockedApi';
import { PaymentTypeHandle } from '@src/constants';
import { useQuery } from '@tanstack/react-query';

type PaymentOption = {
  handle: PaymentTypeHandle;
  value: number;
  planId?: number;
  surcharge?: number;
  payments?: {
    date: string;
    value: number;
  }[];
};

const getPaymentOptions = async () => {
  const { data } = await BPAPI.get<PaymentOption[]>(`/payment/payment-options`);

  return data;
};

export const useGetPaymentOptions = (enabled = true) =>
  useQuery({
    queryKey: [QK_SHOW_3_DOTS_LOADING, QK_GET_PAYMENT_OPTIONS],
    queryFn: async () => {
      return getPaymentOptions();
    },
    enabled,
    staleTime: Infinity,
  });

export type { PaymentOption };
